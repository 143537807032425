<template>
	<div class="home">
		<CarouselBox id="tmtBanner" class="banner mb-3"></CarouselBox>
		<CardGrid
			id="tmtNews"
			class="news mb-3"
			title="最新消息"
			:cards="newsCards"
		></CardGrid>
		<CardGrid
			id="tmtSolution"
			class="solution mb-3"
			title="解決方案"
			:cards="solutionCards"
		></CardGrid>
	</div>
</template>

<script>
import CarouselBox from '@/components/CarouselBox.vue';
import CardGrid from '@/components/CardGrid.vue';

export default {
	name: 'Home',
	components: {
		CarouselBox,
		CardGrid,
	},
	data() {
		return {
			newsCards: [
				{
					id: 'NewsCard1',
					imgUrl: require('../assets/home_news_03.png'),
					imgAlt: '',
					title: '得美特獲得2021智慧城市展AI50優勝獎',
					text:
						'得美特科技以「微氣候能量AI神經系統」參加2021年智慧城市展AI 50競賽，榮獲優勝獎殊榮',
					link: '/news/2021-smart-city-ai-50',
					linkText: '瞭解詳情',
				},
				{
					id: 'NewsCard2',
					imgUrl: require('../assets/home_news_02.png'),
					imgAlt: '',
					title: '得美特獲得AI Star 國際選秀賽特優獎',
					text:
						'得美特科技以「中央空調AI神經系統」參加經濟部舉辦2019年AI Star國際選秀全國前三名之特優獎殊榮',
					link: '/news/2019-ai-star',
					linkText: '瞭解詳情',
				},
				{
					id: 'NewsCard3',
					imgUrl: require('../assets/home_news_01.png'),
					imgAlt: '',
					title: '聯合報採用「微氣候能量AI執行系統」創下超高節能率',
					text:
						'2020年導入「微氣候能量AI執行系統」後再將冰水主機潛力發揮節能25.7%',
					link: '/news/2020-udn',
					linkText: '瞭解詳情',
				},
			],
			solutionCards: [
				{
					id: 'SolutionCard1',
					imgUrl: require('../assets/home_solution_01.png'),
					imgAlt: '',
					title: '中央空調數據神經系統 WINDS.HVAC',
					text:
						'從空氣側到冰水側的全系統控制<br>專家級大數據指標分析監視管理<br>工業無線神經網路全覆蓋易擴充',
					link: '/solution/winds-hvac',
					linkText: '瞭解詳情',
				},
				{
					id: 'SolutionCard2',
					imgUrl: require('../assets/home_solution_02.png'),
					imgAlt: '',
					title: '微氣候能量AI執行系統',
					text:
						'微氣候能量模型的數據分析<br>需求決定供給能量的AI策略<br>人工智慧策略參數執行系統',
					link: '/solution/aies-hvac',
					linkText: '瞭解詳情',
				},
			],
		};
	},
};
</script>

<style scoped></style>
