<template>
	<div :id="id" class="carousel slide" data-ride="carousel">
		<ol class="carousel-indicators">
			<li :data-target="'#' + id" data-slide-to="0" class="active"></li>
			<li :data-target="'#' + id" data-slide-to="1"></li>
			<li :data-target="'#' + id" data-slide-to="2"></li>
		</ol>
		<div class="carousel-inner">
			<router-link
				class="carousel-item active"
				to="/news/2021-smart-city-ai-50"
			>
				<img class="d-block w-100" src="../assets/home_banner_03.png" alt="" />
			</router-link>
			<router-link class="carousel-item" to="/solution/aies-hvac">
				<img class="d-block w-100" src="../assets/home_banner_02.png" alt="" />
			</router-link>
			<router-link class="carousel-item" to="/case/aies-hvac">
				<img class="d-block w-100" src="../assets/home_banner_01.png" alt="" />
			</router-link>
		</div>
		<a
			class="carousel-control-prev"
			:href="'#' + id"
			role="button"
			data-slide="prev"
		>
			<span class="carousel-control-prev-icon" aria-hidden="true"></span>
			<span class="sr-only">上一頁</span>
		</a>
		<a
			class="carousel-control-next"
			:href="'#' + id"
			role="button"
			data-slide="next"
		>
			<span class="carousel-control-next-icon" aria-hidden="true"></span>
			<span class="sr-only">下一頁</span>
		</a>
	</div>
</template>

<script>
export default {
	name: 'CarouselBox',
	props: {
		id: {
			type: String,
			default() {
				return '';
			},
		},
	},
};
</script>

<style scoped></style>
