<template>
	<div class="container-fluid px-0">
		<div class="row">
			<div class="col-12 mt-5">
				<h2 class="title">{{ title }}</h2>
			</div>
		</div>
		<div class="row align-items-stretch">
			<div
				class="col-12 col-md-6 col-xl-4 mb-4"
				:key="id + card.id"
				v-for="card in cards"
			>
				<div class="card h-100 overflow-hidden">
					<img class="card-img-top" :src="card.imgUrl" :alt="card.imgAlt" />
					<div class="card-body">
						<h5 class="card-title font-weight-bold" v-html="card.title"></h5>
						<p class="card-text" v-html="card.text"></p>
						<router-link class="link-text" :to="card.link">
							{{ card.linkText }}
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CardGrid',
	props: {
		id: {
			type: String,
			default() {
				return '';
			},
		},
		title: {
			type: String,
			default() {
				return '';
			},
		},
		cards: {
			type: Array,
			default() {
				return [];
			},
		},
	},
};
</script>

<style scoped>
h2 {
	border-left: solid 10px #ff5722;
	border-bottom: solid 2px #ff5722;
	padding-left: 1.25rem;
	padding-bottom: 0.25rem;
}

.title {
	color: #ff5722;
	font-weight: bold;
}

.link-text {
	color: #03c967;
}

.card-body {
	background-color: #f7f7f7;
}

.card {
	transition: box-shadow 0.35s ease;
}

.card:hover {
	box-shadow: 0 0 10px #aaaaaa;
}
</style>
